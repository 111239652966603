.dashboardContainer {
  display: flex;
  width: 100%;
}

.displayContainer {
  margin:50px  auto ;
  padding:0 20px;
  width: 80%;

}
