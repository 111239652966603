.TravvislogoContainer {
    width: 100%;
  }
  
  .dashBoardTravvisLogo {
    width: 100%;
    margin-bottom: 50px;
  }
  
  .userGreetingContainer {
    width: 200px;
    /* width:10px; */
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  
  .userInfoAndLogo {
    display: flex;
  }
  
  .userLogo {
    width: 70px;
    border-radius: 15px;
    margin-right:5px;
    border: 1px solid #e6e6f0;
    box-shadow: 0px 3px 6px #00000029;
  }
  .logoutButton {
    width: 135px;
    background: none;
    border: none;
    color: #707070;
    font-size: 20px;
    cursor: pointer;
    /* font-family: 'Poppins' */
  }
  
  .greetingText {
    text-align: left;
    letter-spacing: 0;
    color: #818e94;
    font-family: "Courier New", Courier, monospace;
    padding: 0;
    margin: 0;
  }
  
  .userCompanyText {
    text-align: left;
    letter-spacing: 0;
    color: #707070;
    font-family: "Courier New", Courier, monospace;
    padding: 0;
    margin: 0;
  }