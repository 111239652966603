.navigationContainer {
  /* background-color: #1397AB; */
  /* background-image: linear-gradient(red, yellow); */
  background-image: linear-gradient(
    to bottom,
    rgba(16, 123, 139, 0.877),
    rgba(72, 58, 70, 0.74),
    rgba(90, 5, 49, 0.582)
  );
  background-size: 250%;
  min-width: 300px;
  height: 100vh;
  background-position: 50% 85%;
  background-repeat: no-repeat;
}
.navigation {
  background-image: url("images/navBackground.png");
  background-size: 250%;
  width: 100%;
  height: 100%;
  background-position: 70% 95%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.navlogo {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  width: 50px;
}

.navLinksContainer {
  display: flex;
  flex-direction: column;
  width: 75%;
  /* float: right; */
  align-self: flex-end;
}

.materialIcon {
  padding-right: 5px;
}

.navLinks {
  /* background: white; */
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px 10px;
  margin: -0.5px;
  display: flex;
  text-decoration: none;
  /* color: #ff4365; */
}

.unclickedLink {
  color: white;
}
.clickedLink {
  background: white;
  color: #ff4365;
}

.LinkCurve {
  align-self: flex-end;
  /* margin-bottom: -1px; */
  height: 40px;
  margin-right: -0.2px;
}
.rotateCurve {
  align-self: flex-end;
  /* margin-top: -1px; */
  margin-right: -0.2px;

  height: 40px;
  transform: rotate(90deg);
}
.hiddenCurve {
  visibility: hidden;
}

.navOption{
  width: 100%;
  display: flex;
  flex-direction: column;
}