.loginPageContainerBackground {
  width: 100vw;
  height: 100%;
  background-position: 50% 85%;
  background-repeat: no-repeat;
}
.loginPageContainer {
  /* background-image: url("/../components/images/loginpagebackground.png"); */
  top: -72.90966796875px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: transparent
    linear-gradient(
      0deg,
      #ffffff21 -100%,
      #ffffff40 -5%,
      #ffffffbd -1%,
      #ffffffe0 25%,
      #fffffffc 45%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;
  /* border: 1px solid #707070; */
  display: flex;
  flex-direction: column;
}

.formContainer {
  margin: 5% auto;
}
.loginLogoContainer {
  width: 250px;
  margin: 0 auto;
}
.loginLogoContainer p {
  text-align: center;
  font: Regular 20px/24px Museo Sans 300;
  letter-spacing: 0;
  color: #575757;
  font-size: 17px;
}
.loginlogo {
  width: 100%;
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.loginForm input {
  width: 300px;
  height: 35px;
  margin: 1px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 13px #344db280;
  border-radius: 2px 2px 0px 0px;
  opacity: 1;
}
.loginButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.socialMediaButtons {
  display: flex;
  height: 30px;
  text-align: center;
}
.socialMediaButtons p {
  margin: auto;
  font-size: 15px;
}
.logoButton {
  background: none;
  border: none;
  padding: none;
  /* height: 90%; */
}
.buttonlogo {
  /* width: 20px; */
  height: 20px;
  margin: auto 5px;
  cursor: pointer;
}
.loginButton {
  width: 90px;
  background: #ff4365 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 35px #344db257;
  border-radius: 10px;
  border: none;
  text-align: center;
  letter-spacing: 0;
  color: #ffffff;
  cursor: pointer;
}
.registerButton {
  width: 90px;
  height: 30px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 22px #13131326;
  margin: 10% 0 0 70%;
  text-align: center;
}

.newAccountLink {
  text-decoration: none;
  color: #ff4365;
  font-size: 12px;
}
