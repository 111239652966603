.homepageContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.leftSideContent {
  display: flex;
  flex-direction: column;
  width:100%;
  /* justify-content: space-between; */
}

.campaignSearchInput {
  width: 70%;
  margin: auto 0;
  align-self: flex-start;
  box-shadow: 0px 3px 11px #0000001c;
  border-radius: 8px;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  height: 35px;
  margin-top: 10px;
  margin-bottom: 60px;
  align-self: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  font-family: "Courier New", Courier, monospace;

  flex-wrap: wrap;
  width: 94%;
}

/* @media only screen and (max-width: 600px) {
    .headerContainer {
        display: flex;
        flex-direction: column;
      }
  } */

.infoAndOrder {
  display: flex;
  width: 350px;
  height: 30px;
  justify-content: space-between;
  /* margin: auto; */
  align-self: flex-end;
}

.campaignCount {
  display: flex;
}

.campaignCountRes {
  margin: 0;
  padding: 0;
  margin-right: 5px;
}

.campaignOrder {
  display: flex;
}

.infoAndOrderText {
  margin: 0;
  padding: 0;
  margin-right: 5px;
  color: #a2a2a2;
}
.campaignOrderButton {
  display: flex;
  margin-top: -1px;
  font-size: 15px;
  background: none;
  height: 27px;
  /* width:40px; */
  border: none;
}
.viewAllCampaigns {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 5px;
  width: 100px;
  border: solid 1px #ff4365;
  text-decoration: none;
  color: #ff4365;
  align-self: flex-end;
}


